@use 'variables';

.component-about-us {
    h3 {
        font-size: 24px;
        line-height: 27px;
        margin: 15px 0px;
        @include variables.for-tablet-landscape-up {
            font-size: 30px;
            line-height: 40px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;
        margin: 15px 0px;
        @include variables.regular-font;
    }

    .button {
        display: flex;
        font-size: 16px;
        margin: 20px 0px;
        @include variables.bold-font;

        a {
            padding: 18px 24px 18px 24px;
            border: 1px solid #e2e2e2;
            color: #000000;
            border-radius: 4px;
            background-color: #ffffff;
            display: inline;
            transition: ease 0.1s;
            @include variables.for-smallUp-only {
                width: 100%;
                text-align: center;
            }
            @include variables.for-mediumUp-only {
                width: auto;
            }
            @include variables.for-tablet-landscape-up {
                padding: 19px 30px 19px 30px;
            }
        }

        a:hover {
            background-color: #00A053;
            color: #ffffff;
            border: 1px solid #00A053;
            transition: ease 0.1s;
        }
    }

    ul {
        padding-top: 25px;
    }

    li {
        font-size: 16px;
        margin-left: 15px;
        list-style: none;
        line-height: 1.688rem;
        margin-bottom: 0.625rem;
        padding-left: 30px;
        position: relative;
        @include variables.regular-font;
    }

    li:before {
        position: absolute;
        background-color: #000;
        border-radius: 2px;
        border: 2px solid #000;
        content: '';
        display: inline-block;
        margin-right: 15px;
        vertical-align: middle;
        left: 0;
        top: 10px;
        padding-left: 17px;
    }

    .work {
        margin-bottom: 60px;
        @include variables.for-tablet-landscape-up {
            margin-bottom: 130px;
        }

        .work-item {
            max-width: 1360px;
            margin: 0 auto;

            .info {
                .button {
                    margin: 15px 0px;
                }
            }

            .picture {
                position: relative;
                .picture-item {
                    border-radius: 7px;
                }
            }

            .picture::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0.2;
                border-radius: 10px;
                display: none;
            }

            &.top {
                max-width: none;

                .picture {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    .picture-item {
                        object-fit: cover;
                        object-position: center;
                        max-height: 330px;
                        @include variables.for-tablet-portrait-up {
                            max-height: none;
                            height: 430px;
                            object-position: left;
                        }
                        @include variables.for-desktop-up {
                            height: 550px;
                        }
                    }
                }

                .work-content {
                    display: none;
                }

                .picture::before {
                    display: block;
                }

                .top-title {
                    position: absolute;
                    font-size: 30px;
                    line-height: 40px;
                    color: #ffffff;
                    padding: 10px;
                    @include variables.for-tablet-landscape-up {
                        font-size: 50px;
                        line-height: 60px;
                    }
                }
            }

            &.left {
                margin-top: 40px;

                .picture {
                    margin-top: 20px;
                }

                @include variables.for-tablet-landscape-up {
                    display: flex;
                    flex-direction: row-reverse;

                    .info {
                        flex: 0 0 50%;
                        padding-left: 40px;
                    }

                    .picture {
                        flex: 0 0 50%;
                        max-width: 640px;
                        margin-top: 0px;
                    }
                }

                @include variables.for-desktop-up {
                    margin-top: 90px;

                    .info {
                        padding-left: 80px;
                        padding-top: 40px;
                    }
                }

                @include variables.for-large-desktop-up {
                    .info {
                        padding-top: 50px;
                        flex: 0 0 53%;
                    }

                    .picture {
                        flex: 0 0 47%;
                        max-width: 640px;
                    }
                }
            }

            &.right {
                margin-top: 40px;

                .picture {
                    margin-top: 20px;
                }

                @include variables.for-tablet-landscape-up {
                    display: flex;

                    .info {
                        flex: 0 0 50%;
                        padding-right: 40px;
                    }

                    .picture {
                        flex: 0 0 50%;
                        max-width: 640px;
                        margin-top: 0px;
                    }
                }

                @include variables.for-desktop-up {
                    margin-top: 90px;

                    .info {
                        padding-right: 80px;
                        padding-top: 40px;
                    }
                }

                @include variables.for-large-desktop-up {
                    .info {
                        padding-top: 50px;
                        flex: 0 0 53%;
                    }

                    .picture {
                        flex: 0 0 47%;
                        max-width: 640px;
                    }
                }
            }
        }
    }
}
